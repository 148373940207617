/**
 * Created by jerry on 2020/03/5.
 * 储值卡api
 */
import * as API from '@/api/index'

export default {
    // 储值卡列表
    getSavingCard: params => {
        return API.POST('api/savingCard/list', params)
    },
    // 获取产品
    savingCardProduct: params => {
        return API.POST('api/savingCard/product', params)
    },
    // 获取项目
    savingCardProject: params => {
        return API.POST('api/savingCard/project', params)
    },
    // 获取通用次卡
    savingCardGeneralCard: params => {
        return API.POST('api/savingCard/generalCard', params)
    },
    // 获取时效卡
    savingCardTimeCard: params => {
        return API.POST('api/savingCard/timeCard', params)
    },
    // 获取套餐卡
    savingCardPackageCard: params => {
        return API.POST('api/savingCard/packageCard', params)
    },
    // 获取储值卡
    savingCard: params => {
        return API.POST('api/packageCard/savingCard', params)
    },

    // 获取储值卡适用项目
    getSavingCardGoods: params => {
        return API.POST('api/savingCard/savingCardGoods', params)
    },
    //创建储值卡
    createSavingCard: params => {
        return API.POST('api/savingCard/create', params)
    },
    //更新储值卡
    updateSavingCard: params => {
        return API.POST('api/savingCard/update', params)
    },
    // 获取储值卡销售范围
    getSavingCardSaleEntity: params => {
        return API.POST('api/savingCard/savingCardSaleEntity', params)
    },
    // 获取储值卡消耗范围
    getSavingCardConsumeEntity: params => {
        return API.POST('api/savingCard/savingCardConsumeEntity', params)
    },
    // 详情
    getSavingCardDetails: params => {
        return API.POST('api/savingCard/getSavingCardDetails', params)
    },

}