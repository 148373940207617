<template>
  <div class="Groupon content_body padnewProjectProject" v-loading="loading">
    <!-- 搜索 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" class="demo-form-inline" @submit.native.prevent
            @keyup.enter.native="handleSearch">
            <el-form-item label="名称">
              <el-input v-model="Name" @clear="handleSearch" clearable placeholder="请输入名称搜索"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showDialog('add')">添加</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="martp_10">
      <el-table size="small" row-key="ID" :tree-props="{ children: 'Child' }" v-loading="newProjectListLoading">
        <el-table-column label="项目图片">
          <template slot-scope="scope">
            <el-image style="width: 35px; height: 35px" :src="scope.row.ImageURL" fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="newProjectName" label="所属分类"></el-table-column>
        <el-table-column prop="GoodsName" label="名称"></el-table-column>
        <el-table-column prop="GoodsPrice" label="价格"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showDialog('edit',scope.row)">编辑</el-button>
            <el-button v-if="!scope.row.ParentID" type="danger" size="small" @click="handelDelete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加商品 -->
    <el-dialog :title="dialogTitle" :visible.sync="addDialogVisible" width="1000px" @close="closeDialog">
      <el-form size="small" :model="newProject" ref="newProject" label-width="100px" class="demo-ruleForm">
        <el-form-item label="所属分类" prop="Name" :rules="[{ required: true, message: '所属分类不能为空',  },]">
          <el-cascader v-model="newProject.Name"
            :props="{children:'Child',value:'ID',label:'Name',emitPath:false,expandTrigger: 'hover'}"
            :show-all-levels="true" clearable filterable :options="CategoryList" @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="选择商品">
          <el-row>
            <el-col :span="9">
              <el-input type="age" v-model="searchValue" autocomplete="off"></el-input>
            </el-col>
            <el-col :span="15">
              <el-button type="primary" size="small" @click="openGoodList">添加商品</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-table :data="goodsRangeList" style="width: 100%" size="small">
            <el-table-column prop="date" label="商品名称">
              <template slot-scope="scope">
                <el-input v-model="scope.row.Name" placeholder="请输入内容"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="Alias" label="商品类型"></el-table-column>
            <el-table-column prop="Price" label="销售价格"></el-table-column>
            <el-table-column prop="address" label="操作" width="180">
              <template slot-scope="scope">
                <el-button type="danger" size="small">
                  删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click='closeDialog'>取 消</el-button>
        <el-button size="small" type="primary" @click="">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑分类 -->
    <el-dialog :title="dialogTitle" :visible.sync="editDialogVisible" width="1000px" @close="closeDialog">
      <el-scrollbar class="el_scrollbar_height">
        <el-form size="small" :model="editForm" ref="newProject" label-width="100px" class="demo-ruleForm">
          <el-form-item label="所属分类" prop="Name" :rules="[{ required: true, message: '所属分类不能为空',  },]">
            <el-cascader v-model="value"
              :props="{ checkStrictly: true ,children:'children',value:'value',label:'label',emitPath:false}"
              :show-all-levels="false" clearable filterable :options="options" @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input></el-input>
          </el-form-item>
          <el-form-item label="分类图片" prop="Logo_file">
            <el-upload action="#" :limit="1" list-type="picture-card" :file-list="newProject.ImageURL"
              :before-upload="($event)=>beforeAvatarUpload($event,true)">
              <i slot="default" class="el-icon-camera-solid" style="font-size: 40px; color: #999"></i>
              <div slot="file" slot-scope="{file}">
                <el-image class="el-upload-list__item-thumbnail" :id="file.uid" :src="file.url"
                  :preview-src-list="showFileList_1" :z-index="9999" fit="cover"></el-image>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="DialogPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>

                  <span class="el-upload-list__item-delete" @click="handleRemove()">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="商品详情:">
            <!-- 富文本编辑器 -->
            <quill-editor ref="myTextEditor" v-model="newProject.Memo" :options="editorOption"
              style="width: 90%; height: 400px"></quill-editor>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click='closeDialog'>取 消</el-button>
        <el-button size="small" type="primary" @click="">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 商品列表 -->
    <el-dialog title="添加套餐卡商品" :visible="GoodDialogVisible" width="900px">
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-collapse v-model="collapseIndex" accordion @change="collapseChange">
              <el-collapse-item title="产品" name="1">
                <el-input v-model="productName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeProduct" :data="productList" node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode"
                    :default-checked-keys="productDefaultCheckedKeys"
                    :default-expanded-keys="productDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(1, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="项目" name="2">
                <el-input v-model="projectName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeProject" :data="projectList" node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode"
                    :default-checked-keys="projectDefaultCheckedKeys"
                    :default-expanded-keys="projectDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(2, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="通用次卡" name="3">
                <el-input v-model="generalName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeGeneral" :data="generalCardList" node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode"
                    :default-checked-keys="generalDefaultCheckedKeys"
                    :default-expanded-keys="generalDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(3, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="时效卡" name="4">
                <el-input v-model="timeName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeTime" :data="timeCardList" node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode"
                    :default-checked-keys="timeDefaultCheckedKeys" :default-expanded-keys="timeDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(4, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="储值卡" name="5">
                <el-input v-model="chuzhiKaName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeChuzhiKa" :data="savingCardList" node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode"
                    :default-checked-keys="savingDefaultCheckedKeys" :default-expanded-keys="savingDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(5, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
            </el-collapse>
          </el-col>

          <el-col :span="16">
            <el-table size="small" :data="
                packageCardProduct.filter(
                  (data) =>
                    !productName ||
                    data.Name.toLowerCase().includes(productName.toLowerCase())
                )
              " v-if="collapseIndex == 1">
              <el-table-column prop="Name" label="产品名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsnewProjectName" label="产品类型"></el-table-column>
              <el-table-column label="销售价格" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(3, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardProject.filter(
                  (data) =>
                    !projectName ||
                    data.Name.toLowerCase().includes(projectName.toLowerCase())
                )
              " v-if="collapseIndex == 2">
              <el-table-column prop="Name" label="项目名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsnewProjectName" label="项目类型"></el-table-column>
              <el-table-column label="销售价格" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(4, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardGeneral.filter(
                  (data) =>
                    !generalName ||
                    data.Name.toLowerCase().includes(generalName.toLowerCase())
                )
              " v-if="collapseIndex == 3">
              <el-table-column prop="Name" label="通用次卡名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsnewProjectName" label="通用次卡类型"></el-table-column>
              <el-table-column label="售价" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(5, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardTime.filter(
                  (data) =>
                    !timeName ||
                    data.Name.toLowerCase().includes(timeName.toLowerCase())
                )
              " v-if="collapseIndex == 4">
              <el-table-column prop="Name" label="时效卡名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsnewProjectName" label="时效卡类型"></el-table-column>
              <el-table-column label="售价" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(6, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardSaving.filter(
                  (data) =>
                    !chuzhiKaName ||
                    data.Name.toLowerCase().includes(chuzhiKaName.toLowerCase())
                )
              " v-if="collapseIndex == 5">
              <el-table-column prop="Name" label="储值卡名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsnewProjectName" label="储值卡类型"></el-table-column>
              <el-table-column label="售价" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(7, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <!--展示全部-->
            <el-table size="small" max-height="500px" :data="packageCardSelectAll" v-if="collapseIndex == ''">
              <el-table-column prop="Name" label="商品名称"></el-table-column>
              <el-table-column prop="Alias" label="商品类型" width="100px"></el-table-column>
              <el-table-column prop="OldPrice" label="销售价格"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(8, scope.row, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="GoodDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addProjectSelect">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import API from "@/api/Marketing/MarketingPlay/pad";
import APISaving from "@/api/iBeauty/Goods/savingCard";
import utils from "@/components/js/utils.js";
// 引入富文本编辑样式以及组件
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "padnewProjectProject",
  created() {
    var that = this;
    // 富文本编辑器的配置
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
      ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
      [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
      [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
      [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
      [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
      [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
      [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
      [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
      [{ font: [] }], // 字体种类-----[{ font: [] }]
      [{ align: [] }], // 对齐方式-----[{ align: [] }]
      ["clean"], // 清除文本格式-----['clean']
      ["image"],
    ]; // 链接、图片、视频-----['link', 'image', 'video']

    that.editorOption = {
      modules: {
        //工具栏定义的
        toolbar: toolbarOptions,
      },
      //主题
      theme: "snow",
      placeholder: "请输入正文",
    };
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      Name: "",
      value: "",
      options: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
            },
            {
              value: "daohang",
              label: "导航",
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
            },
            {
              value: "form",
              label: "Form",
            },
            {
              value: "data",
              label: "Data",
            },
            {
              value: "notice",
              label: "Notice",
            },
            {
              value: "navigation",
              label: "Navigation",
            },
            {
              value: "others",
              label: "Others",
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      loading: false,
      newProjectListLoading: false,
      newProjectList: [], //分类列表
      newProject: {
        Name: "",
        ParentID: 0,
        Active: true,
        ImageURL: [],
        Memo: "",
      },
      searchValue: "",
      showFileList_1: null, //分类主图预览
      moveIndex: "", //移动类型
      moveID: "", //移动ID
      // 添加分类的弹窗是否开启
      addDialogVisible: false,
      editDialogVisible: false,
      GoodDialogVisible: false,
      dialogVisibleType: "",
      dialogTitle: "",
      editForm:{

      },
      CategoryList: [], //分类
      collapseIndex:'1',
      tableData:[],

      productDefaultCheckedKeys:[],
      productDefaultExpandedKeys: [1],
      projectDefaultCheckedKeys:[],
      projectDefaultExpandedKeys:[1],
      generalDefaultCheckedKeys:[],
      generalDefaultExpandedKeys:[1],
      timeDefaultCheckedKeys:[],
      timeDefaultExpandedKeys:[1],
      savingDefaultCheckedKeys:[],
      savingDefaultExpandedKeys:[1],
      
      projectName:'',
      productName: "",
      generalName:'',
      timeName:'',
      chuzhiKaName:'',
      packageName:'',

      productList:[],
      projectList:[],
      generalCardList:[],
      savingCardList:[],
      timeCardList:[],
      savingCardPackageCard:[],

      packageCardProduct:[],
      packageCardProject:[],
      packageCardGeneral:[],
      packageCardTime:[],
      packageCardSaving:[],

      packageCardSelectAll:[],

      goodsRangeProductList:[],
      goodsRangeProjectList:[],
      goodsRangeGeneralCardList:[],
      goodsRangeTimeCardList:[],
      goodsRangeSavingCardCardList:[],

      goodsRangeList:[],

    };
  },

  mounted() {
    var that = this;
    that.handleSearch();
    // 获取分类列表
    that.getCategoryList();
    // 获取产品
    that.productData()
    // 获取项目
    that.projectData()
    // 获取通用次卡
    that.generalCardData()
    // 获取储值卡列表
    that.savingCardData()
    // 获取时效卡
    that.timeCardData()
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 获取分类列表
    getCategoryList() {
      const that = this;
      const params = {
        Name: that.name,
        Active: that.active,
      };
      that.CategoryListLoading = true;
      API.CategoryAll(params)
        .then((res) => {
          console.log(res);
          if (res.StateCode == 200) {
            console.log(res);
            if (res.Data.length) {
              let arr = res.Data.filter(
                (item) => item.Child && item.Child.length > 0
              );
              that.CategoryList = arr;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.CategoryListLoading = false;
        });
    },
    // 获取产品
    productData: function () {
      var that = this;
      let params = {
        productName: that.productName,
      };
      APISaving.savingCardProduct(params).then((res) => {
        if (res.StateCode == 200) {
          this.productList = res.Data;
          that.setRecursion(res.Data);
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取项目
    projectData: function () {
      var that = this;
      APISaving.savingCardProject().then((res) => {
        if (res.StateCode == 200) {
          that.projectList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取通用次卡
    generalCardData: function () {
      var that = this;
      APISaving.savingCardGeneralCard().then((res) => {
        if (res.StateCode == 200) {
          that.generalCardList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取储值卡列表
    savingCardData: function () {
      var that = this;
      APISaving.savingCard().then((res) => {
        if (res.StateCode == 200) {
          that.savingCardList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取时效卡
    timeCardData: function () {
      var that = this;
      APISaving.savingCardTimeCard().then((res) => {
        if (res.StateCode == 200) {
          that.timeCardList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
     // 获取套餐卡
    packageCardData: function () {
      var that = this;
      that.loading = true;
      APISaving.savingCardPackageCard()
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardPackageCard = res.Data;
            that.setRecursion(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsGoods == 0) {
          data[i].PID = "0" + data[i].ID;
        } else {
          data[i].PID = "1" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    handleSearch() {
      console.log("搜索");
    },
    closeDialog() {
      this.GoodDialogVisible = false;
    },
    handleChange() {
      console.log("change");
    },
    // 添加/编辑
    showDialog(type, row) {
      const that = this;
      that.dialogVisibleType = type;
      this.$refs.newProject ? this.$refs.newProject.resetFields() : "";
      switch (type) {
        case "add":
          that.dialogTitle = "添加商品";
          that.addDialogVisible = true;
          that.newProject = {
            Name: "",
            ParentID: 0,
            Active: true,
            ImageURL: [],
            Memo: "",
          };
          break;
        case "edit":
          that.dialogTitle = "编辑";
          that.editDialogVisible = true;
          if (row.ImageURL) {
            utils.getCanvasBase64(row.ImageURL).then((val) => {
              that.showFileList_1 = [val];
              that.newProject = {
                ID: row.ID,
                Name: row.Name,
                ParentID: 0,
                Active: row.Active,
                ImageURL: [{ name: "", url: val }],
                Memo: row.Memo,
              };
            });
          } else {
            that.showFileList_1 = [];
            that.newProject = {
              Name: row.Name,
              ParentID: 0,
              Active: row.Active,
              ImageURL: [{ name: "", url: "" }],
              Memo: row.Memo,
            };
          }

          break;
      }
    },
    // 删除
    handelDelete() {
      this.$confirm("此操作将删除该分类下所有商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    openGoodList() {
      this.GoodDialogVisible = true;
    },
    //设置明细中选择的产品、项目...
    changeAll(index, data) {
      if (index === 1) {
        this.packageCardProduct.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "产品",
          Name: data.Name,
        });
      } else if (index === 2) {
        this.packageCardProject.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "项目",
          Name: data.Name,
        });
      } else if (index === 3) {
        this.packageCardGeneral.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "通用次卡",
          Name: data.Name,
        });
      } else if (index === 4) {
        this.packageCardTime.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "时效卡",
          Name: data.Name,
        });
      } else {
        this.packageCardSaving.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "储值卡",
          Name: data.Name,
        });
      }
      this.packageCardSelectAll = this.packageCardProduct.concat(
        this.packageCardProject,
        this.packageCardGeneral,
        this.packageCardTime,
        this.packageCardSaving
      );
    },
    remove(index, rowindex, indexall) {
      var that = this;
      if (index === 1) {
        for (let i = 0; i < this.goodsRangeList.length; i++) {
          if (
            this.goodsRangeList[i].Alias == rowindex.Alias &&
            this.goodsRangeList[i].PID == rowindex.PID &&
            this.goodsRangeList[i].Amount == rowindex.Amount &&
            this.goodsRangeList[i].Price == rowindex.Price
          ) {
            that.goodsRangeList.splice(i, 1);
            this.allPrice();
            return;
          }
        }
      } else if (index == 2) {
        for (let i = 0; i < this.goodsRangeLargessList.length; i++) {
          if (
            this.goodsRangeLargessList[i].Alias == rowindex.Alias &&
            this.goodsRangeLargessList[i].PID == rowindex.PID &&
            this.goodsRangeLargessList[i].Amount == rowindex.Amount &&
            this.goodsRangeLargessList[i].Price == rowindex.Price
          ) {
            that.goodsRangeLargessList.splice(i, 1);
            return;
          }
        }
      } else if (index == 3) {
        this.packageCardProduct.splice(rowindex, 1);
      } else if (index == 4) {
        this.packageCardProject.splice(rowindex, 1);
      } else if (index == 5) {
        this.packageCardGeneral.splice(rowindex, 1);
      } else if (index == 6) {
        this.packageCardTime.splice(rowindex, 1);
      } else if (index == 7) {
        this.packageCardSaving.splice(rowindex, 1);
      } else {
        if (rowindex.Alias == "产品") {
          this.packageCardProduct.splice(
            this.packageCardProduct.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else if (rowindex.Alias == "项目") {
          this.packageCardProject.splice(
            this.packageCardProject.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else if (rowindex.Alias == "通用次卡") {
          this.packageCardGeneral.splice(
            this.packageCardGeneral.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else if (rowindex.Alias == "时效卡") {
          this.packageCardTime.splice(
            this.packageCardTime.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else {
          this.packageCardSaving.splice(
            this.packageCardSaving.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        }
        this.packageCardSelectAll.splice(indexall, 1);
      }
      // this.collapseChange();
    },
    collapseChange(){},
    // 确认选中适用项目
    addProjectSelect: function () {
      var that = this;

        that.goodsRangeProductList = that.packageCardProduct; //产品集合
        that.goodsRangeProjectList = that.packageCardProject; //项目集合
        that.goodsRangeGeneralCardList = that.packageCardGeneral; //通用次卡集合
        that.goodsRangeTimeCardList = that.packageCardTime; //时效卡集合
        that.goodsRangeSavingCardCardList = that.packageCardSaving; //储值卡集合
        that.goodsRangeList = that.goodsRangeProductList.concat(
          that.goodsRangeProjectList,
          that.goodsRangeGeneralCardList,
          that.goodsRangeTimeCardList,
          that.goodsRangeSavingCardCardList
        );
      that.GoodDialogVisible = false;
    },
    /**  监听富文本 内容变化  */
    // onEditorChange({ html }) {
    // let that = this;
    // const regex = new RegExp("<img", "gi");
    // that.Memo_1 = html.replace(regex, `<img style="max-width: 100%;"`);
    // },
  },
};
</script>

<style lang="scss" >
.padnewProjectProject {
  .el-dialog {
    // height: 60vh;
  }
  .el_scrollbar_height {
    height: 55vh;
    .el-scrollbar__wrap {
      overflow-x: hidden !important;
    }
    #container {
      width: 100%;
      height: 55vh;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
    .el-upload.el-upload--picture-card {
      width: 100px !important;
      height: 100px !important;
      line-height: 115px;
    }
  }
  .el-scrollbar_height_range {
    height: 35vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>